import React, {Component} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {Container} from "react-bootstrap";

export class AGBEn extends Component {

    render() {
        return <Container>
            <h1>Data Protection Notices</h1>
            You can find our data protection notices here: <a href="https://media.graphcms.com/T7tYXw8fTw6UdqiYrfea">Data
            Protection Notices</a>
            <br/><br/>
            <h1>Licenses</h1>
            This page uses various third-party libraries.
            <br/><br/>
            <h2>File Import</h2>
            The import is done with an open-source library from tresor.one.
            <br/>
            You can download it here: https://github.com/tresorone/tresor-import
            <br/><br/>
            <h2>Image Sources</h2>
            GDPR: <a href="https://www.flaticon.com/free-icons/gdpr" title="GDPR icons">GDPR icons created by Becris -
            Flaticon</a>
        </Container>;
    }

}