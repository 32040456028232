import React, {Component} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {Container} from "react-bootstrap";

export class ImpressumEn extends Component {

    render() {
        return <Container>
            <h1>Legal Notice</h1>
            <b>Information according to §5 TMG & responsible for content according to §55 Abs. 2 RStV:</b> <br/>

            fin4tec GmbH<br/>

            Regensburger Straße 106<br/>

            06132 Halle (Saale)<br/>

            mail: <a href="mailto:info@pinc.business">info@pinc.business</a><br/><br/>

            <b>Represented by the managing directors:</b><br/>

            Francesco Freder (CEO/CTO)<br/>

            Matthias Weisbrich (CFO)<br/>

            Stefan Rank (CMO)<br/><br/>

            <b>Commercial register:</b> HRB 31273<br/>
            <b>Register court:</b> Amtsgericht Stendal<br/><br/>


            <b>Disclaimer - Copyright</b><br/>

            The contents and works created by fin4tec GmbH on these pages are subject to German copyright law. The
            duplication,
            processing, distribution and any kind of exploitation outside the limits of copyright law require the
            written consent of the
            respective author or creator. Downloads and copies of this page are only permitted for private,
            non-commercial use.
            As far as the contents on this page were not created by fin4tec GmbH, the copyrights of third parties are
            respected. In particular,
            third-party content is marked as such. Should you nevertheless become aware of a copyright infringement, we
            ask for a
            corresponding notice. Upon becoming aware of legal violations, we will immediately remove such content.<br/><br/>

            <b>EU dispute resolution</b><br/>

            The European Commission provides a platform for online dispute resolution (OS):
            https://ec.europa.eu/consumers/odr. You can find our email address in the legal notice above.<br/><br/>

            <b>Consumer dispute resolution/Universal arbitration board</b><br/>

            We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration
            board.
        </Container>;
    }

}