import React, {Component} from "react";
import "./LandingPage.css";
import Background from "./img/Background.svg";
import Teaser from "./img/Teaser.webp";
import Logo from "./img/Logo.png";
import Laptop from "./img/Laptop.webp";
import Tablet from "./img/Tablet.webp";
import Mobile from "./img/Mobile.webp";
import IconShield from "./img/IconShield.png";
import IconGlass from "./img/IconGlass.png";
import IconHands from "./img/IconHands.png";
import IconLock from "./img/IconLock.png";
import FooterLogo from "./img/Footer-Logo.webp";
import {NavLink} from "react-router-dom";

export const APP_URL = "https://app.pinc.business";

export class TeaserPageEn extends Component {

    render() {
        return <div className="">

            <div className="main-container">

                <div className="header-links">
                    <a href={APP_URL}>Sign In/ Register</a>
                    <a href={APP_URL}>Demo</a>
                </div>

                <div className="main-content text">
                    <img src={Background} alt="Background" className="background-image"/>

                    <div className="first-area">
                        <div className="left-lane lane-40vh">
                            <div className="logo-container">
                                <img src={Logo} alt="Logo" className="logo-image"/>
                            </div>
                            <div className="text-center">
                                <span className="logo-pinc">Pinc</span>
                                <span className="logo-dot">.</span>
                                <span className="logo-business">Business</span>
                            </div>
                        </div>

                        <div className="right-lane lane-60vh">
                            <img src={Teaser} alt="Teaser" className="teaser-pic"/>

                            <div className="justify-end color-white text-area">
                                <div className="text-alle-assets-inhalt">
                                    <span className="text-bold">All</span> <span
                                    className="text-light">Assets, </span><span
                                    className="text-bold">all</span>
                                    <span className="text-light"> Accounts</span>
                                </div>
                            </div>
                            <div className="justify-end color-white text-area">
                                <div className="text-alle-assets-inhalt">
                                    <span className="text-bold">Everything at a glance</span>
                                </div>
                            </div>
                            <div className="justify-end color-white text-area">
                                <a href="https://app.pinc.business" className="action-btn">&#9654; &nbsp;Test Beta
                                    now</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-content text">
                    <div className="first-area">
                        <div className="header-title">Your tool for financial freedom</div>
                    </div>
                </div>

                <div className="main-content text">
                    <div className="second-area second-area-text lane-50vh color-white text-medium">
                        Organize your <b>income</b> and <b>expenses</b><br/>
                        <b>easily</b> with our integrated <b className="text-bold">household plan,</b><br/>
                        <b>Based on this, you can optimize your investment and</b><br/>
                        <b>investment opportunities.</b><br/>
                        <div className="more-space"></div>
                        <a href="https://app.pinc.business" className="action-btn">&#9654; &nbsp;Create a household plan
                            now</a>
                    </div>

                    <img src={Laptop} alt="Laptop" className="second-area-pic"/>
                </div>


                <div className="main-content text">

                    <img src={Tablet} alt="Tablet" className="third-area-pic"/>

                    <div className="third-area third-area-text lane-60vh text-medium color-white">

                        <div className="text-alle-assets-inhalt text-bold color-dark">Asset Management rethought</div>

                        <div className="third-area-subtext">
                            Manage all your assets with <b>Pinc.Business</b>.
                            Whether <b>stocks, ETFs, crypto P2P loans,
                            real estate</b>, or various other forms of investment. <b>Pinc.Business</b> connects your
                            accounts and wallets
                            and <b>provides you with all the management tools you
                            need.</b>
                            <div className="more-space"></div>
                            <a href="https://app.pinc.business" className="action-btn">&#9654; &nbsp;Start now</a>
                        </div>
                    </div>

                </div>

                <div className="main-content text">

                    <div className="forth-area-text lane-70vh text-medium color-white">

                        <div className="text-alle-assets-inhalt text-bold color-dark">Analyze your budget
                            professionally
                        </div>

                        <div className="forth-area-subtext">
                            &#10003; <b>Encrypted</b> and anonymous integration of your portfolio<br/>
                            &#10003; <b>Automatic bank retrieval</b> from over 3000 banks<br/>
                            &#10003; Compatibility with <b>trustworthy platforms</b><br/>
                            &#10003; <b>All real-time prices</b> of your <b>stocks, ETFs and cryptocurrencies</b><br/>
                            &#10003; Overview of all your <b>P2P loans, wallets and accounts</b>
                        </div>
                    </div>

                    <img src={Mobile} alt="Mobile" className="forth-area-pic"/>
                </div>

                <div className="main-content text fifth-section">
                    <div className="header-title">No compromises on security</div>
                </div>

                <div className="main-content text fifth-section">
                    <div className="lane-50vh">
                        <div className="description-box">
                            <img src={IconShield} alt="Shield" className="icon"/>
                            <div className="description-text-box color-dark">
                                <div className="header ">Encryption</div>
                                <div className="description">We only collect user data that is absolutely necessary for
                                    registration on our platform.
                                </div>
                            </div>
                        </div>
                        <div className="description-box">
                            <img src={IconLock} alt="Lock" className="icon"/>
                            <div className="description-text-box color-dark">
                                <div className="header ">Transparency</div>
                                <div className="description">We obtain the data required for the use of Pinc.Business
                                    via a completely
                                    isolated data management.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lane-50vh">
                        <div className="description-box">
                            <img src={IconGlass} alt="Glass" className="icon"/>
                            <div className="description-text-box color-dark">
                                <div className="header ">Anonymity</div>
                                <div className="description">We process your actions, transactions, entries and all
                                    other
                                    activities without using your personal data.
                                </div>
                            </div>
                        </div>
                        <div className="description-box">
                            <img src={IconHands} alt="Hands" className="icon"/>
                            <div className="description-text-box color-dark">
                                <div className="header ">Trust</div>
                                <div className="description">We attach great importance to the fact that all data,
                                    including that from and at service providers
                                    for authentication and payment processing, is stored on databases in Germany.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-content text fifth-section ">
                    <div className="sub-title">You can find all information on the subject of security <a
                        href="https://app.pinc.business/s/agb">here.</a></div>
                </div>

                <div className="footer-section">

                    <div className="footer-lane">
                        <div className="footer-header">Contact</div>
                        <div className="footer-contact">
                            info@pinc.business<br/>
                            <br/>
                            fin4tec GmbH<br/>
                            Regensburger Straße 106<br/>
                            06132 Halle (Saale)
                        </div>
                    </div>

                    <div className="footer-lane">
                        <NavLink to="Impressum" className="footer-header">Imprint</NavLink>
                        <NavLink to="AGB" className="footer-header">Terms and Conditions</NavLink>
                        <NavLink to="UeberUns" className="footer-header">About us</NavLink>
                    </div>

                    <div className="footer-lane">
                        <img src={FooterLogo} alt="Footer Logo" className="forth-area-pic"/>
                    </div>
                </div>
            </div>
        </div>;
    }
}