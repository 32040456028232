import React, {Component} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./about-us.css";
import {Container} from "react-bootstrap";
import {FaGithub, FaGlobe, FaLinkedinIn, FaXing} from "react-icons/fa";

export class AboutUsEn extends Component {

    render() {
        return <Container className="about-us">
            <h1 className="text-center">About us</h1>

            <link rel="stylesheet"
                  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/svg-with-js.min.css"
                  integrity="sha512-W3ZfgmZ5g1rCPFiCbOb+tn7g7sQWOQCB1AkDqrBG1Yp3iDjY9KYFh/k1AWxrt85LX5BRazEAuv+5DV2YZwghag=="
                  crossOrigin="anonymous"
                  referrerPolicy="no-referrer"/>

            <section className="team-section py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                                <div className="card-body p-4">
                                    <div className="member-profile position-absolute w-100 text-center">
                                        <img className="rounded-circle mx-auto d-inline-block shadow-sm"
                                             src="/img/francesco.jpg" alt="Francesco Freder"/>
                                    </div>
                                    <div className="card-text pt-1">
                                        <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Francesco
                                            Freder</h5>
                                        <div className="mb-3 text-center">Co-founder / CTO / CEO</div>
                                        <div>
                                            Francesco takes over the IT of Pinc.Business. He combines the area of
                                            software development, architecture
                                            and DevOps and translates our suggestions into the language of the machines
                                            with full power. From the MLU
                                            to the tech lead and from the simple demo account to the multi-asset
                                            portfolio, his interests are perfectly aligned
                                            with Pinc.Business.
                                        </div>
                                    </div>
                                </div>


                                <div className="card-footer theme-bg-primary border-0 text-center">
                                    <ul className="social-list list-inline mb-0 mx-auto">
                                        <li className="list-inline-item">
                                            <a className="text-dark"
                                               href="https://www.linkedin.com/in/francesco-freder-2b4251114/">
                                                <FaLinkedinIn/>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-dark"
                                               href="https://www.xing.com/profile/Francesco_Freder/">
                                                <FaXing/>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="https://github.com/FrancescoFreder/">
                                                <FaGithub/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                                <div className="card-body p-4">
                                    <div className="member-profile position-absolute w-100 text-center">
                                        <img className="rounded-circle mx-auto d-inline-block shadow-sm"
                                             src="/img/stefan.jpg" alt=""/>
                                    </div>
                                    <div className="card-text pt-1">
                                        <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Stefan
                                            Rank</h5>
                                        <div className="mb-3 text-center">Co-founder / CMO</div>
                                        <div>
                                            Stefan is the creative head of Pinc.Business. As a media manager, his
                                            interests range from
                                            campaign formation to media law. Communication is his specialty. His
                                            expertise as a storyteller,
                                            author, journalist, moderator, and coach has been provided to large media
                                            houses, foundations, and corporations.
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer theme-bg-primary border-0 text-center">
                                    <ul className="social-list list-inline mb-0 mx-auto">
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="https://www.xing.com/profile/Stefan_Rank4/">
                                                <FaXing/>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="https://www.stefanrank.de/">
                                                <FaGlobe/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                                <div className="card-body p-4">
                                    <div className="member-profile position-absolute w-100 text-center">
                                        <img className="rounded-circle mx-auto d-inline-block shadow-sm"
                                             src="/img/matthias.jpg" alt=""/>
                                    </div>
                                    <div className="card-text pt-1">
                                        <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Matthias
                                            Weisbrich</h5>
                                        <div className="mb-3 text-center">Co-founder / CFO</div>
                                        <div>
                                            Our man of numbers and contracts. As the managing director of various
                                            companies, he brings the entrepreneurial
                                            skills to make Pinc.Business the player among portfolio management and
                                            wealth (ab) formation platforms, together with the ideas of the team and our
                                            users.
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer theme-bg-primary border-0 text-center">
                                    <ul className="social-list list-inline mb-0 mx-auto">
                                        <li className="list-inline-item">
                                            <a className="text-dark"
                                               href="https://www.xing.com/profile/Matthias_Weisbrich/">
                                                <FaXing/>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="https://stratag.de/">
                                                <FaGlobe/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </section>

        </Container>;
        ;
        ;
    }

}