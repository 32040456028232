import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";

export class AGB extends Component {

    render() {
        return <Container>
            <h1>Datenschutzhinweise</h1>
            Unsere Datenschutzhinweise findest du hier: <a href="https://media.graphcms.com/T7tYXw8fTw6UdqiYrfea">Datenschutzhinweise</a>
            <br/><br/>
            <h1>Lizenzen</h1>
            Diese Seite verwendet verschiedene Bibliotheken von Drittanbietern.
            <br/><br/>
            <h2>Dateiimport</h2>
            Der Import geschieht mit einer Open-Source Bibliothek von tresor.one.
            <br/>
            Den Download findest du unter: https://github.com/tresorone/tresor-import
            <br/><br/>
            <h2>Bildquellen</h2>
            DSGVO: <a href="https://www.flaticon.com/free-icons/gdpr" title="GDPR icons">GDPR icons created by Becris - Flaticon</a>
        </Container>;
    }

}
