import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./about-us.css";
import { Container } from "react-bootstrap";
import { FaGithub, FaGlobe, FaLinkedinIn, FaXing } from "react-icons/fa";

export class AboutUs extends Component {

    render() {
        return <Container className="about-us">
            <h1 className="text-center">Über uns</h1>

            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/svg-with-js.min.css"
                  integrity="sha512-W3ZfgmZ5g1rCPFiCbOb+tn7g7sQWOQCB1AkDqrBG1Yp3iDjY9KYFh/k1AWxrt85LX5BRazEAuv+5DV2YZwghag==" crossOrigin="anonymous"
                  referrerPolicy="no-referrer" />

            <section className="team-section py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                                <div className="card-body p-4">
                                    <div className="member-profile position-absolute w-100 text-center">
                                        <img className="rounded-circle mx-auto d-inline-block shadow-sm"
                                             src="/img/francesco.jpg" alt="Francesco Freder" />
                                    </div>
                                    <div className="card-text pt-1">
                                        <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Francesco Freder</h5>
                                        <div className="mb-3 text-center">Co-founder / CTO / CEO</div>
                                        <div>
                                            Francesco übernimmt die IT von Pinc.Business. Er vereint den Bereich Software-Entwicklung, -Architektur
                                            sowie DevOps und übersetzt mit voller Power unsere Vorschläge in die Sprache der Maschinen. Von der MLU
                                            zum Tech-Lead und vom simplen Demo-Konto zum Multi-Asset-Portfolio sind seine Interessen perfekt auf
                                            Pinc.Business abgestimmt.
                                        </div>
                                    </div>
                                </div>


                                <div className="card-footer theme-bg-primary border-0 text-center">
                                    <ul className="social-list list-inline mb-0 mx-auto">
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="https://www.linkedin.com/in/francesco-freder-2b4251114/">
                                                <FaLinkedinIn />
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="https://www.xing.com/profile/Francesco_Freder/">
                                                <FaXing />
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="https://github.com/FrancescoFreder/">
                                                <FaGithub />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/*<div className="col-12 col-md-6">*/}
                        {/*    <div className="card border-0 shadow-lg pt-5 my-5 position-relative">*/}
                        {/*        <div className="card-body p-4">*/}
                        {/*            <div className="member-profile position-absolute w-100 text-center">*/}
                        {/*                <img className="rounded-circle mx-auto d-inline-block shadow-sm"*/}
                        {/*                     src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="" />*/}
                        {/*            </div>*/}
                        {/*            <div className="card-text pt-1">*/}
                        {/*                <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Pèpe</h5>*/}
                        {/*                <div className="mb-3 text-center">Co-founder</div>*/}
                        {/*                <div>*/}
                        {/*                    Unser Navigationssystem im Start-Up-Chaos. Er kennt den Weg, verliert das Ziel nie aus den Augen und*/}
                        {/*                    beherrscht die Klaviatur der Diplomatie genauso, wie die klare Ansage. Als Projektadvisor treibt er gerne*/}
                        {/*                    innovative Ideen zum Erfolg – unter anderem im Finanzsektor.*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="card-footer theme-bg-primary border-0 text-center">*/}
                        {/*            <ul className="social-list list-inline mb-0 mx-auto">*/}

                        {/*            </ul>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                                <div className="card-body p-4">
                                    <div className="member-profile position-absolute w-100 text-center">
                                        <img className="rounded-circle mx-auto d-inline-block shadow-sm"
                                             src="/img/stefan.jpg" alt="" />
                                    </div>
                                    <div className="card-text pt-1">
                                        <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Stefan Rank</h5>
                                        <div className="mb-3 text-center">Co-founder / CMO</div>
                                        <div>
                                            Stefan ist der kreative Kopf von Pinc.Business. Als Medien Manager reichen seine Interessen von
                                            Kampagnenbildung bis Medienrecht. Kommunikation ist sein Spezialgebiet. Seine Expertise als Storyteller,
                                            Autor, Journalist, Moderator und Coach stellte er bisher großen Medienhäusern, Stiftungen und Konzernen
                                            zur Verfügung.
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer theme-bg-primary border-0 text-center">
                                    <ul className="social-list list-inline mb-0 mx-auto">
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="https://www.xing.com/profile/Stefan_Rank4/">
                                                <FaXing />
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="https://www.stefanrank.de/">
                                                <FaGlobe />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                                <div className="card-body p-4">
                                    <div className="member-profile position-absolute w-100 text-center">
                                        <img className="rounded-circle mx-auto d-inline-block shadow-sm"
                                             src="/img/matthias.jpg" alt="" />
                                    </div>
                                    <div className="card-text pt-1">
                                        <h5 className="member-name mb-0 text-center text-primary font-weight-bold">Matthias Weisbrich</h5>
                                        <div className="mb-3 text-center">Co-founder / CFO</div>
                                        <div>
                                            Unser Mann der Zahlen und Verträge. Als Geschäftsführer diverser Firmen bringt er das unternehmerische
                                            Können mit, um zusammen mit den Ideen des Teams und unseren Nutzern Pinc.Business zu dem Player unter den
                                            Portfoliomanagement- und Vermögens(ab)bildungsplattformen zu machen.
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer theme-bg-primary border-0 text-center">
                                    <ul className="social-list list-inline mb-0 mx-auto">
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="https://www.xing.com/profile/Matthias_Weisbrich/">
                                                <FaXing />
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-dark" href="https://stratag.de/">
                                                <FaGlobe />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </section>

        </Container>;
        ;
        ;
    }

}
