import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";

export class Impressum extends Component {

    render() {
        return <Container>
            <h1>Impressum</h1>
            <b>Angaben lt. §5 TMG & verantwortlich für Inhalt lt. §55 Abs. 2 RStV:</b>  <br />

            fin4tec GmbH<br />

            Regensburger Straße 106<br />

            06132 Halle (Saale)<br />

            mail: <a href="mailto:info@pinc.business">info@pinc.business</a><br /><br />

            <b>Vertreten durch die Geschäftsführer:</b><br />

            Francesco Freder (CEO/CTO)<br />

            Matthias Weisbrich (CFO)<br />

            Stefan Rank (CMO)<br /><br />

            <b>Handelsregister:</b> HRB 31273<br />
            <b>Registergericht:</b> Amtsgericht Stendal<br /><br />


            <b>Disclaimer - Urheberrecht</b><br />

            Die durch die fin4tec GmbH erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
            Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
            jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            Soweit die Inhalte auf dieser Seite nicht von der fin4tec GmbH erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um
            einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br/><br />

            <b>EU-Streitschlichtung</b><br/>

            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
            https://ec.europa.eu/consumers/odr. Unsere E-Mail-Adresse finden Sie oben im Impressum.<br/><br />

            <b>Verbraucherstreitbeilegung/Universalschlichtungsstelle</b><br/>

            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </Container>;
    }

}
