import React, {Component} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {TeaserPage} from "./teaserPage/TeaserPage";
import {Impressum} from "./staticPages/Impressum";
import {AGB} from "./staticPages/AGB";
import {AboutUs} from "./staticPages/AboutUs";
import {TeaserPageEn} from "./teaserPage/TeaserPageEn";
import {AboutUsEn} from "./staticPages/AboutUsEn";
import {ImpressumEn} from "./staticPages/ImpressumEn";
import {AGBEn} from "./staticPages/AGBEn";

export default class App extends Component {

    render() {
        // @ts-ignore
        if ((navigator.language || navigator.userLanguage).startsWith('de')) {
            return <BrowserRouter>
                <Routes>
                    <Route path="/" element={<TeaserPage/>}/>
                    <Route path="/Impressum" element={<Impressum/>}/>
                    <Route path="/AGB" element={<AGB/>}/>
                    <Route path="/UeberUns" element={<AboutUs/>}/>
                </Routes>
            </BrowserRouter>;
        } else {
            return <BrowserRouter>
                <Routes>
                    <Route path="/" element={<TeaserPageEn/>}/>
                    <Route path="/Impressum" element={<ImpressumEn/>}/>
                    <Route path="/AGB" element={<AGBEn/>}/>
                    <Route path="/UeberUns" element={<AboutUsEn/>}/>
                </Routes>
            </BrowserRouter>;
        }
    }

}
