import React, { Component } from "react";
import "./LandingPage.css";
import Background from "./img/Background.svg";
import Teaser from "./img/Teaser.webp";
import Logo from "./img/Logo.png";
import Laptop from "./img/Laptop.webp";
import Tablet from "./img/Tablet.webp";
import Mobile from "./img/Mobile.webp";
import IconShield from "./img/IconShield.png";
import IconGlass from "./img/IconGlass.png";
import IconHands from "./img/IconHands.png";
import IconLock from "./img/IconLock.png";
import FooterLogo from "./img/Footer-Logo.webp";
import { NavLink } from "react-router-dom";

export const APP_URL = "https://app.pinc.business";

export class TeaserPage extends Component {

    render() {
        return <div className="">

            <div className="main-container">

                <div className="header-links">
                    <a href={APP_URL}>Anmelden/ Registieren</a>
                    <a href={APP_URL}>Demo</a>
                </div>

                <div className="main-content text">
                    <img src={Background} alt="Background" className="background-image" />

                    <div className="first-area">
                        <div className="left-lane lane-40vh">
                            <div className="logo-container">
                                <img src={Logo} alt="Logo" className="logo-image" />
                            </div>
                            <div className="text-center">
                                <span className="logo-pinc">Pinc</span>
                                <span className="logo-dot">.</span>
                                <span className="logo-business">Business</span>
                            </div>
                        </div>

                        <div className="right-lane lane-60vh">
                            <img src={Teaser} alt="Teaser" className="teaser-pic" />

                            <div className="justify-end color-white text-area">
                                <div className="text-alle-assets-inhalt">
                                    <span className="text-bold">Alle</span> <span className="text-light">Assets, </span><span
                                    className="text-bold">alle</span>
                                    <span className="text-light"> Konten</span>
                                </div>
                            </div>
                            <div className="justify-end color-white text-area">
                                <div className="text-alle-assets-inhalt">
                                    <span className="text-bold">Alles auf einen Blick</span>
                                </div>
                            </div>
                            <div className="justify-end color-white text-area">
                                <a href="https://app.pinc.business" className="action-btn">&#9654; &nbsp;Jetzt Beta testen</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-content text">
                    <div className="first-area">
                            <div className="header-title">Dein Tool zu finanzieller Freiheit</div>
                    </div>
                </div>

                <div className="main-content text">
                    <div className="second-area second-area-text lane-50vh color-white text-medium">
                        Organisiere Deine <b>Einnahmen</b> und <b>Ausgaben</b><br />
                        <b>kinderleicht</b> mit unserem integrierten <b className="text-bold">Haushaltsplan,</b><br />
                        <b>Darauf aufbauend kannst Du Deine Investment- und</b><br />
                        <b>Anlagemöglichkeiten optimieren.</b><br />
                        <div className="more-space"></div>
                        <a href="https://app.pinc.business" className="action-btn">&#9654; &nbsp;Jetzt Haushaltsplan anlegen</a>
                    </div>

                    <img src={Laptop} alt="Laptop" className="second-area-pic" />
                </div>


                <div className="main-content text">

                    <img src={Tablet} alt="Tablet" className="third-area-pic" />

                    <div className="third-area third-area-text lane-60vh text-medium color-white">

                        <div className="text-alle-assets-inhalt text-bold color-dark">Asset Management neu gedacht</div>

                        <div className="third-area-subtext">
                            Verwalte mit <b>Pinc.Business</b> alle Deine Assets.
                            Egal ob <b>Aktien, ETFs, Krypto P2P-Kredite,
                            Immobilien</b>, oder diverse andere Anlageformen. <b>Pinc.Business</b> verbindet Deine Konten und Wallets
                            und <b>liefert dir alle Management-Tools die
                            Du brauchst.</b>
                            <div className="more-space"></div>
                            <a href="https://app.pinc.business" className="action-btn">&#9654; &nbsp;Jetzt starten</a>
                        </div>
                    </div>

                </div>

                <div className="main-content text">

                    <div className="forth-area-text lane-70vh text-medium color-white">

                        <div className="text-alle-assets-inhalt text-bold color-dark">Analysiere professionell Dein Budget</div>

                        <div className="forth-area-subtext">
                            &#10003; <b>Verschlüsselte</b> und anonyme Integration Deines Portfolios<br />
                            &#10003; <b>Automatischer Bankabruf</b> von über 3000 Banken<br />
                            &#10003; Kompatibilität mit <b>vertrauenswürdigen Plattformen</b><br />
                            &#10003; <b>Alle Echt-Zeit-Kurse</b> Deiner <b>Aktien, ETFs und Kryptowährungen</b><br />
                            &#10003; Überblick über all Deine <b>P2P-Kredite, Wallets und Konten</b>
                        </div>
                    </div>

                    <img src={Mobile} alt="Mobile" className="forth-area-pic" />
                </div>

                <div className="main-content text fifth-section">
                    <div className="header-title">Keine Kompromisse beim Thema Sicherheit</div>
                </div>

                <div className="main-content text fifth-section">
                    <div className="lane-50vh">
                        <div className="description-box">
                            <img src={IconShield} alt="Shield" className="icon" />
                            <div className="description-text-box color-dark">
                                <div className="header ">Verschlüsselung</div>
                                <div className="description">Wir erheben ausschließlich Nutzerdaten, die zum Anmelden auf unserer Plattform zwingend
                                    nötig sind.
                                </div>
                            </div>
                        </div>
                        <div className="description-box">
                            <img src={IconLock} alt="Lock" className="icon" />
                            <div className="description-text-box color-dark">
                                <div className="header ">Transparenz</div>
                                <div className="description">Die zur Nutzung von Pinc.Business benötigten Daten beziehen wir über ein komplett
                                    isoliertes Datenmanagement.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lane-50vh">
                        <div className="description-box">
                            <img src={IconGlass} alt="Glass" className="icon" />
                            <div className="description-text-box color-dark">
                                <div className="header ">Anonymität</div>
                                <div className="description">Deine Aktionen, Transaktionen, Eingaben und alle weiteren
                                    Aktivitäten verarbeiten wir ohne Nutzung Deiner persönlichen Daten.
                                </div>
                            </div>
                        </div>
                        <div className="description-box">
                            <img src={IconHands} alt="Hands" className="icon" />
                            <div className="description-text-box color-dark">
                                <div className="header ">Vertrauen</div>
                                <div className="description">Wir legen größten Wert darauf, dass sämtliche Daten, auch die von und bei Dienstleistern
                                    für Authentifizierung und Zahlungsabwicklung, auf Datenbanken in Deutschland hinterlegt sind.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-content text fifth-section ">
                    <div className="sub-title">Alle Infos zum Thema Sicherheit findest Du <a href="https://app.pinc.business/s/agb">hier.</a></div>
                </div>

                <div className="footer-section">

                    <div className="footer-lane">
                        <div className="footer-header">Kontakt</div>
                        <div className="footer-contact">
                        info@pinc.business<br/>
                        <br/>
                        fin4tec GmbH<br/>
                        Regensburger Straße 106<br/>
                        06132 Halle (Saale)
                        </div>
                    </div>

                    <div className="footer-lane">
                        <NavLink to="Impressum" className="footer-header">Impressum</NavLink>
                        <NavLink to="AGB" className="footer-header">AGB</NavLink>
                        <NavLink to="UeberUns" className="footer-header">Über uns</NavLink>
                    </div>

                    <div className="footer-lane">
                        <img src={FooterLogo} alt="Footer Logo" className="forth-area-pic" />
                    </div>
                </div>
            </div>
        </div>;
    }
}
